export default function UCCTestimonial() {
  return (
    <section className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
      <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
      <div className="mx-auto max-w-2xl lg:max-w-4xl">
        <h2 className="text-base text-center text-xl font-semibold leading-7 text-sky-700">
          UCC user testimonial
        </h2>
        <figure className="mt-10">
          <blockquote className="text-center text-xl  leading-8 text-gray-900 sm:text-2xl sm:leading-9">
            <p>
              “My Mother had a GP appointment and was told she had suspected
              urgent problems with her heart. They asked her to visit A&E but
              having spent 9 hours in our local A&E a few months ago I decided
              to take her to the Urgent Care Centre.
            </p>
            <p className="mt-5">
              She was seen within 30 minutes and had blood test, CT contrast
              scan, blood test and an X ray which confirmed that her situation
              was not acute. The staff were very helpful, kind, cheerful and
              made her feel at ease. We are happy with the treatment that she
              received. Thank you everyone for making a stressful time feel less
              stressful.”
            </p>
          </blockquote>
          <figcaption className="mt-10">
            <div className="mt-4 flex items-center justify-center space-x-3 text-base">
              <div className="font-semibold text-gray-900">Jenny Pattinson</div>
              <svg
                width={3}
                height={3}
                viewBox="0 0 2 2"
                aria-hidden="true"
                className="fill-gray-900"
              >
                <circle r={1} cx={1} cy={1} />
              </svg>
              <div className="text-gray-600">Verified Google review</div>
            </div>
          </figcaption>
        </figure>
      </div>
    </section>
  );
}
