import { PhoneIcon } from "@heroicons/react/20/solid";
import UCCMap from "../../components/maps/UCCMap";

export default function MESUCCLocation() {
  return (
    <div className="overflow-hidden bg-white py-20 sm:py-20">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg flex flex-col items-end">
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Urgent Care Center
              </p>
              <p className="mt-2 text-2xl font-semibold tracking-tight text-gray-900 sm:text-3xl">
                Cromwell Hospital
              </p>

              <p className="mt-6 text-lg leading-8 text-gray-600">
                164-178 Cromwell Road
              </p>
              <p className=" text-lg leading-8 text-gray-600">London</p>
              <p className=" text-lg leading-8 text-gray-600">SW5 0TU</p>
              <p className=" text-lg leading-8 text-gray-600 flex flex-row items-center">
                <PhoneIcon aria-hidden="true" className="h-4 w-4  mr-2" />
                02072 444886
              </p>

              <div className="mt-10 flex items-center gap-x-6 ">
                <a
                  href="tel:+4402072444886"
                  className="flex items-center rounded-md bg-sky-600 px-3.5 py-2.5 text-sm md:text-md font-semibold text-white shadow-sm hover:bg-teal-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                >
                  <PhoneIcon aria-hidden="true" className="h-6 w-6  mr-2" />
                  Contact UCC
                </a>

                <a
                  href="https://www.cromwellhospital.com/services-specialties/urgent-care/"
                  className="text-md font-semibold leading-6 text-sky-800 hover:text-teal-600"
                >
                  Visit UCC website <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
          </div>
          <UCCMap />
        </div>
      </div>
    </div>
  );
}
