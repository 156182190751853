import { ChevronRightIcon } from "@heroicons/react/20/solid";

export default function TransferList() {
  const services = [
    "Patient transport to and from GPs, hospitals,  OPDs and investigations using a patient centred team & vehicle service",
    "Inter-hospital transfers",
    "High dependency transfers",
    "Convey hospital medical teams to a pre-hospital setting or  to a scene of an incident",
    "Organ and blood transfers",
    "GP urgent referrals for admission via A&E",
    "Using fast response vehicles to move medical staff to clients locations",
  ];
  return (
    <div className="bg-white py-24 sm:py-32" id="transfers-section">
      <div className="mx-auto grid max-w-7xl gap-x-20 gap-y-10 px-6 lg:px-8 xl:grid-cols-3">
        <div className="max-w-2xl">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Private Ambulance Transport Services
          </h2>
          <h3 className="mt-6 text-lg leading-8 text-gray-600">
            We offer both <strong>emergency and non-emergency</strong> ambulance
            transport services.
          </h3>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Our emergency transport includes transporting critically ill clients
            to and between hospitals. For non-emergency needs, we provide
            Patient Transport Services (PTS), facilitating routine and booked
            transport between homes, clinics, and hospitals.
          </p>
        </div>
        <ul className="flex flex-col gap-x-8 gap-y-6  xl:col-span-2">
          {services.map((service) => (
            <li key={service}>
              <div className="grid grid-flow-col items-start md:items-center md:mt-0 justify-start gap-x-6">
                <ChevronRightIcon
                  aria-hidden="true"
                  className="h-5 w-5 text-sky-700 mt-1 md:mt-0"
                />
                <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                  {service}
                </h3>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
