import CTA from "../../components/CTA";
import Clients from "../../components/Clients";
import SeparatorLeft from "../../components/SeparatorLeft";
import WorkWithUs from "../../components/WorkWithUs";
import PreHospitalContent from "./PreHospitalContent";
import PreHospitalHero from "./PreHospitalHero";
import SeparatorRight from "../../components/SeparatorRight";
import PreHospitalEthos from "./PreHospitalEthos";
import Staff from "../../components/Staff";
import Technology from "../../components/Technology";
import Compliance from "../../components/Compliance";

export default function PreHospital() {
  return (
    <div>
      <PreHospitalHero />
      <SeparatorRight />
      <PreHospitalContent />
      <Technology />
      <PreHospitalEthos />
      <SeparatorLeft />
      <Staff />
      <CTA />
      <Compliance />
      <Clients />
      <WorkWithUs />
    </div>
  );
}
