import CTA from "../../components/CTA";
import Compliance from "../../components/Compliance";
import Mission from "../../components/Mission";
import SeparatorLeft from "../../components/SeparatorLeft";
import SeparatorRight from "../../components/SeparatorRight";
import UrgentCare from "../../components/UrgentCare";

import WorkWithUs from "../../components/WorkWithUs";
import Highlights from "../../components/highlights";
import Hero from "./Hero";
import ServicesTiles from "./ServicesTiles";

export default function Home() {
  return (
    <div className="relative overflow-hidden">
      <Hero />
      <ServicesTiles />
      <SeparatorRight />
      <Mission />
      <SeparatorLeft />
      <UrgentCare />
      <Compliance />
      <CTA />
      <Highlights />

      <WorkWithUs />
      {/* section for careers? section for social media?*/}
    </div>
  );
}
