import UCCHero from "./UCCHero";
import SeparatorRight from "../../components/SeparatorRight";

import UCCStats from "./UCCStats";
import UCCInfo from "./UCCInfo";
import UCCLocation from "./UCCLocation";
import UCCTestimonial from "./UCCtestimonial";

export default function UCC() {
  return (
    <>
      <UCCHero />
      <SeparatorRight />
      <UCCStats />
      <UCCInfo />
      <UCCTestimonial />
      <UCCLocation />
    </>
  );
}
