import SeparatorRight from "../../components/SeparatorRight";
import Highlights from "../../components/highlights";
import CareersCTA from "./CareersCTA";
import CareersHero from "./CareersHero";
import CareerOffers from "./CareersOffers";

export default function Careers() {
  return (
    <div>
      <CareersHero />
      <SeparatorRight />
      <CareerOffers />
      <CareersCTA />
      <Highlights />
    </div>
  );
}
