// import {
//   HospitalIcon,
//   CalendarIcon,
//   TechIcon,
// } from "@heroicons/react/24/solid";

export default function Highlights() {
  const features = [
    {
      id: 1,
      title: "Leading Urgent Care Services",
      description:
        "MES operates a premier Urgent Care Centre at the renowned Cromwell Hospital in London, offering exceptional care through state-of-the-art facilities and advanced medical technology.",
      icon: "fa-regular fa-hospital",
    },
    {
      id: 2,
      title: "Tailored Event Medical Management",
      description:
        "Specialised in providing bespoke medical coverage for various events, including music festivals, sporting events, and corporate gatherings, with thorough pre-event planning and responsive on-site support.",
      icon: "fa-regular fa-calendar",
    },
    {
      id: 3,
      title: "Commitment to Cutting-Edge Technology",
      description:
        "Dedicated to leveraging the latest advancements in medical equipment and communication systems to enhance the efficiency and effectiveness of their medical services.",
      icon: "fa-solid fa-laptop-medical",
    },
  ];

  return (
    <div className="bg-white py-20 sm:py-20">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-3xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-6">
            At MES, we are dedicated to delivering comprehensive medical
            management services.
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Our commitment is to ensure the safety and well-being of our
            patients through meticulous <strong>planning, expert care</strong>,
            and <strong>responsive support</strong>. We focus on every detail to
            provide the highest level of medical management and ensure that all
            health needs are met with precision and compassion.
          </p>
        </div>
        <div className="mx-auto mt-10 border-t border-gray-200 pt-10 sm:mt-12 sm:pt-12 lg:mx-0 lg:max-w-none">
          <div className="grid grid-cols-1 lg:flex lg:space-x-8 lg:justify-center lg:pb-10">
            {features.map((feature) => (
              <article
                key={feature.id}
                className="flex-shrink-0 w-4/5 lg:w-80 flex flex-col items-center lg:items-start justify-self-center"
              >
                <div className="bg-white bg-opacity-75 p-4 rounded-lg group relative flex items-center lg:items-start gap-x-6">
                  <div className="flex h-11 w-11 flex-none items-center justify-center">
                    <i
                      className={`${feature.icon} fa-2xl text-sky-800 lg:mt-6`} // Adjust size and colors as needed
                    />
                  </div>
                  <div className="group relative">
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900">
                      {feature.title}
                    </h3>
                    <p className="mt-5 text-sm leading-6 text-gray-600">
                      {feature.description}
                    </p>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
