import { Link } from "react-router-dom";

export default function Privacy() {
  return (
    <div className="overflow-hidden bg-white py-20 px-6 sm:px-12 max-w-7xl mx-auto">
      <div className="justify-start mb-8">
        <Link to="/">
          <span className="relative text-lg leading-6 text-gray-600 underline underline-offset-4 ">
            &larr; Return to Home
          </span>
        </Link>
      </div>
      <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Privacy Policy
      </h1>
      <div className="mt-8 space-y-6">
        <div>
          <h2 className="text-xl font-semibold text-sky-700">
            1. Introduction
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Medical Emergency Solutions values your privacy. This Privacy Policy
            explains how we collect, use, and protect your personal data when
            you use our website.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-semibold text-sky-700">
            2. Data Collection
          </h2>
          <div className="mt-2 space-y-2 text-gray-600">
            <p>
              <strong>Personal Data:</strong> We collect personal data such as
              your name and email address when you voluntarily submit it through
              our contact form. This information is used solely to respond to
              your inquiries.
            </p>
            <p>
              <strong>Non-Personal Data:</strong> We automatically collect
              non-personally identifiable information, such as your IP address,
              browser type, and the pages you visit. This data helps us improve
              our website.
            </p>
          </div>
        </div>

        <div>
          <h2 className="text-xl font-semibold text-sky-700">3. Cookies</h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            We use cookies to enhance your experience on our website. Cookies
            are small text files placed on your device. You can manage cookies
            through your browser settings. For more information, visit{" "}
            <a
              href="https://www.allaboutcookies.org"
              className="text-sky-700 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              AllAboutCookies.org
            </a>
            .
          </p>
          <div className="mt-4 space-y-2 text-gray-600">
            <p>
              <strong>Essential Site Cookie:</strong> PHPSESSID – This cookie is
              essential for site functionality and expires when you close your
              browser.
            </p>
            <p>
              <strong>Google Analytics Cookies:</strong> _utma, _utmb, _utmc,
              _utmz – These cookies collect information about site usage to help
              us improve our website. For more details, visit{" "}
              <a
                href="https://www.google.com/analytics/terms/us.html"
                className="text-sky-700 hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Analytics Privacy Policy
              </a>
              .
            </p>
          </div>
        </div>

        <div>
          <h2 className="text-xl font-semibold text-sky-700">4. Use of Data</h2>
          <ul className="mt-2 list-disc list-inside text-lg leading-8 text-gray-600">
            <li>Respond to your inquiries and provide customer support.</li>
            <li>Improve our website and services.</li>
            <li>Analyze site usage to enhance user experience.</li>
          </ul>
        </div>

        <div>
          <h2 className="text-xl font-semibold text-sky-700">
            5. Data Protection
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            We implement appropriate security measures to protect your personal
            data. We do not share your personal data with third parties except
            as required by law or with your consent.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-semibold text-sky-700">6. Your Rights</h2>
          <ul className="mt-2 list-disc list-inside text-lg leading-8 text-gray-600">
            <li>Access, correct, or delete your personal data.</li>
            <li>Object to or restrict processing of your data.</li>
            <li>
              Withdraw consent at any time if processing is based on consent.
            </li>
          </ul>
          <p className="mt-4 text-lg leading-8 text-gray-600">
            To exercise these rights, please contact us at: <br />
            Email:{" "}
            <a
              href="mailto:info@medicalemergencysolutions.co.uk"
              className="text-sky-700 hover:underline"
            >
              info@medicalemergencysolutions.co.uk
            </a>
          </p>
        </div>

        <div>
          <h2 className="text-xl font-semibold text-sky-700">
            7. Data Retention
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            We retain personal data only as long as necessary for the purposes
            for which it was collected, or as required by law.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-semibold text-sky-700">
            8. International Transfers
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            If we transfer your personal data outside the UK, we ensure it is
            protected by appropriate safeguards.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-semibold text-sky-700">
            9. Changes to Privacy Policy
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page with an updated effective date.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-semibold text-sky-700">
            10. Contact Information
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            For questions about this Privacy Policy, please contact us at:
            <br />
            Email:{" "}
            <a
              href="mailto:info@medicalemergencysolutions.co.uk"
              className="text-sky-700 hover:underline"
            >
              info@medicalemergencysolutions.co.uk
            </a>
            <br />
            Address: Medical Emergency Solutions,Unit 9, 199 Camford Way, Luton
            LU3 3AN
          </p>
        </div>
      </div>
    </div>
  );
}
