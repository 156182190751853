import { Link } from "react-router-dom";

export default function ErrorPage() {
  return (
    <div className="relative bg-sky-900 isolate py-20 lg:py-40 px-6 pt-18 lg:px-8 flex flex-col items-center">
      <h1 className="text-xl font-bold tracking-tight text-white ">
        404 error - Not Found!
      </h1>
      <h2 className="text-2xl md:text-4xl font-bold tracking-tight text-white py-20">
        We are sorry, the page you are trying to access does not exist.
      </h2>
      <Link
        to="/"
        className="rounded-md bg-white px-3.5 py-2.5 text-md font-semibold text-sky-900 shadow-sm hover:bg-teal-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
      >
        Return to home
      </Link>
    </div>
  );
}
