import pattern from "../assets/pattern.svg";

export default function SeparatorRight() {
  return (
    <div className="relative z-20 w-full flex justify-items-end">
      <img
        src={pattern}
        alt="Pattern"
        className="w-full -mt-16 min-h-40 lg:w-auto lg:-mt-28 lg:h-60 mr-0 ml-auto"
      />
    </div>
  );
}
