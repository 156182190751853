export default function UCCInfo() {
  return (
    <div className="overflow-hidden bg-white mb-28 py-10 sm:py-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-sky-700">
                Excellence in the Urgent Medical Care
              </h2>
              <h3 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                A Patient-Centric Approach
              </h3>

              <p className="mt-6 text-lg leading-8 text-gray-600">
                One of the key distinguishing factors of our Urgent Care Centre
                is its emphasis on{" "}
                <strong>accessibility and convenience</strong>. People in need
                of urgent medical attention can receive prompt and high-quality
                care at a time convenient to them. Our online appointment
                booking system allows patient to streamline their visit and
                <strong> minimise waiting times.</strong>
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Our staff at our Urgent Care Centre are a cornerstone of our
                success. Comprising a team of{" "}
                <strong>
                  experienced and dedicated healthcare professionals
                </strong>
                , including emergency medical consultants and senior emergency
                nurses, the Centre ensures that patients receive the best
                possible care. The staff's proficiency in a wide range of
                medical specialties enables them to address diverse medical
                concerns, from minor injuries to acute illnesses, with
                efficiency and expertise.
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                The Centre is equipped with modern diagnostic tools, imaging
                equipment, and treatment modalities, enabling{" "}
                <strong>rapid and accurate assessments</strong> of patients'
                conditions. This technological prowess facilitates timely
                decision-making and ensures that patients receive appropriate
                care without unnecessary delays.
              </p>
            </div>
          </div>{" "}
          <img
            alt="Doctor smiling whilst examining a patient"
            src="/images/ucc-doctor.jpg"
            width={2432}
            height={1442}
            className="w-full  rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] mx-auto"
          />
        </div>
      </div>
    </div>
  );
}
