export default function PreHospitalContent() {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
        <div className="mx-auto flex flex-col w-full sm:gap-y-20">
          <div className="lg:pr-8 lg:pt-4">
            <div className="mb-10 px-6 md:px-0 md:mb-0">
              <h2 className="text-base font-semibold leading-7 text-sky-700">
                Emergency and Non-Emergency
              </h2>
              <h3 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Convenient, Comprehensive Care Delivered to You
              </h3>
              <div className="flex flex-col lg:flex-row w-full gap-x-20">
                <p className="mt-6 text-lg leading-8 text-gray-600 w-full">
                  MES is equipped with state-of-the-art medical equipment and
                  vehicles, <strong>guaranteeing rapid response times</strong>{" "}
                  and the ability to{" "}
                  <strong>administer life-saving treatments on-site</strong>. We
                  provide comprehensive medical services, including first aid,
                  triage, paramedics, and doctors, tailored for both business
                  and family needs.
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600 w-full">
                  If required, our doctors can travel to your home or workplace
                  with full investigation equipment. We can offer services such
                  as{" "}
                  <strong>
                    blood analysis, cardiac screening, echocardiograms, and a
                    complete health screening service
                  </strong>
                  . MES aims to deliver the highest standards of care to
                  patients in all care settings, in order to support in their
                  path to recovery.
                </p>
              </div>
            </div>
          </div>
          <img
            alt="Paramedic carrying equipment bags inside fast response car"
            src="/images/fast_response.jpg"
            className=" h-[26rem] object-left-bottom object-cover md:h-auto md:w-full md:rounded-xl shadow-xl ring-1 ring-gray-400/10 md:object-center md:object-cover "
          />
        </div>
      </div>
    </div>
  );
}
