export default function PreHospitalEthos() {
  return (
    <div className="bg-sky-900 py-32 sm:py-32 ">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-5xl text-center">
          <h2 className="text-3xl font-semibold tracking-tight text-white sm:text-4xl mb-6">
            At MES, we treat patients with compassion, dignity and respect
          </h2>
          <p className="mt-2 text-lg leading-18 text-white mb-10">
            Our staff are approachable at all times and act with{" "}
            <strong>integrity, fairness, and courtesy.</strong> This ensures
            that MES respects the <strong>human rights</strong> of all patients,
            does not discriminate unlawfully or unfairly, and treats everyone
            with impartiality.
          </p>
        </div>
      </div>
    </div>
  );
}
