import React from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import UCCMarker from "./UCCMarker";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: 51.92201359471436,
  lng: -0.48304625767040715,
};

function MESMap() {
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={18}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {map && (
        <UCCMarker
          position={center}
          title="Cromwell Hospital Urgent Care Centre"
        />
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(MESMap);
