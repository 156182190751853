import { HashLink as Link } from "react-router-hash-link";

export default function UCCHero() {
  return (
    <div className="relative isolate py-36 sm:py-52 lg:py-50 px-6 pt-16 lg:px-8">
      <img
        src="/images/hero-ucc.jpg"
        alt="Doctor at a desk holding a stethoscope around their neck"
        className="absolute inset-0 -z-10 h-full w-full object-cover object-center "
      />
      <div className="ml-0 h-full mr-auto max-w-2xl md:ml-10 flex flex-col">
        <div className="justify-start mb-8">
          <Link to="/">
            <span className="relative text-lg leading-6 text-gray-300 underline underline-offset-4 ">
              &larr; Return to Home
            </span>
          </Link>
        </div>
        <div className="text-start">
          <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
            Urgent Care Centre
          </h1>
          <p className="mt-6 text-xl leading-8 text-white ">
            At the heart of MES’s offerings is our dedication to providing
            top-notch medical services for our Urgent Care Centre (UCC), located
            within the world renowned{" "}
            <a
              href="https://www.cromwellhospital.com/"
              target="blank"
              rel="noopener noreferrer"
              className="font-bold hover:text-teal-600"
            >
              Cromwell Hospital
            </a>{" "}
            in
            <strong> London.</strong>
          </p>
          <p className="mt-6 text-xl leading-8 text-white ">
            Our state-of-the-art facilities and advanced medical technology
            contribute to its standing as the{" "}
            <strong>leading Urgent Care Centre in the UK.</strong>
          </p>
          <div className="mt-10 flex items-center justify-start gap-x-6">
            <Link
              smooth
              to="#UCC-section"
              className="rounded-md bg-sky-600 px-3.5 py-2.5 text-md font-semibold text-white shadow-sm hover:bg-teal-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Learn about UCC
            </Link>
            <a
              href="https://www.cromwellhospital.com/services-specialties/urgent-care/"
              className="text-md font-semibold leading-6 text-white hover:text-teal-600"
            >
              Visit UCC website <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
