import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function CookiesPopup() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (!cookiesAccepted) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setIsVisible(false);
  };

  const handleDecline = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 shadow-lg p-4 z-50">
        <div className="container mx-auto flex flex-col sm:flex-row items-center justify-between">
          <p className="text-gray-700 text-sm ">
            We use cookies to improve your experience on our website. By
            continuing to use this site, you agree to our use of cookies. For
            more details, read our{" "}
            <Link to="/privacy" className="text-sky-600 underline">
              Cookie Policy.
            </Link>
          </p>
          <div className="mt-4 sm:mt-0 flex space-x-4">
            <button
              onClick={handleAccept}
              className="bg-sky-700 text-white py-2 px-4 rounded hover:bg-sky-800"
            >
              Accept
            </button>
            <button
              onClick={handleDecline}
              className="text-gray-600 py-2 px-4 rounded hover:bg-gray-200"
            >
              Decline
            </button>
          </div>
        </div>
      </div>
    )
  );
}
