import ContactForm from "./ContactForm";
import ContactHero from "./ContactHero";
import MESLocation from "./MESLocation";
import SeparatorRight from "../../components/SeparatorRight";

import MESUCCLocation from "./MESUCCLocation";

export default function Contacts() {
  return (
    <>
      <ContactHero />
      <SeparatorRight />
      <MESLocation />
      <MESUCCLocation />
      <ContactForm />
    </>
  );
}
