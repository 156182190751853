import Highlights from "../../components/highlights";
import AboutServices from "./AboutServices";

import Directors from "./Directors";
import People from "../../components/People";
import AboutUsHero from "./AboutUsHero";
import Clients from "../../components/Clients";
import SeparatorRight from "../../components/SeparatorRight";
import SeparatorLeft from "../../components/SeparatorLeft";
import Location from "../../components/Location";
import Compliance from "../../components/Compliance";

export default function AboutUs() {
  return (
    <>
      <AboutUsHero />
      <SeparatorRight />
      <Directors />
      <People />
      <Compliance />
      <Clients />
      <AboutServices />
      <SeparatorLeft />
      <Highlights />
      <Location />
    </>
  );
}
