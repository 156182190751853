import React, { useState, useRef } from "react";

import emailjs from "@emailjs/browser";

export default function ContactForm() {
  const form = useRef();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    agreePrivacyPolicy: false,
    agreeTerms: false,
    agreeCommunication: false,
    cv: null,
  });
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData({
      ...formData,
      [name]:
        type === "checkbox" ? checked : type === "file" ? files[0] : value,
    });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.email) newErrors.email = "Email is required.";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Email is invalid.";
    if (!formData.message) newErrors.message = "Message is required.";
    if (!formData.agreePrivacyPolicy)
      newErrors.agreePrivacyPolicy = "You must agree to the privacy policy.";
    if (!formData.agreeTerms)
      newErrors.agreeTerms = "You must agree to the terms and conditions.";
    return newErrors;
  };

  const onCancel = () => {
    setErrors({});
    setMessage("");
    setFormData({
      name: "",
      email: "",
      message: "",
      agreePrivacyPolicy: false,
      agreeTerms: false,
      agreeCommunication: false,
      cv: null,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        {
          publicKey: process.env.REACT_APP_PUBLIC_KEY,
        }
      )
      .then(
        () => {
          console.log("SUCCESS!");
          setMessage("Email sent successfully!");
          setFormData({
            name: "",
            email: "",
            message: "",
            agreePrivacyPolicy: false,
            agreeTerms: false,
            agreeCommunication: false,
            cv: null,
          });
          setErrors({});
        },
        (error) => {
          console.log("FAILED...", error.text);
          console.error("Error sending email:", error);
          setMessage("Failed to send email. Please try again later.");
        }
      );
  };

  return (
    <form ref={form} onSubmit={handleSubmit} className="py-20 px-10">
      <div className="space-y-12 max-w-7xl flex flex-col mx-auto -centre">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-center  leading-7 text-sky-900 my-5 text-3xl font-semibold sm:text-4xl">
            Contact us today to explore how we can assist you
          </h2>
          <p className="mt-2 text-sm leading-6 text-gray-600 text-center">
            Please fill out the form below with your details and inquiries.
          </p>
          <p className="mt-2 text-sm leading-6 text-gray-600 text-center">
            One of our experts will review your information and get in touch
            with you shortly to discuss how we can help.
          </p>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 ">
            <div className="sm:col-span-3">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Your name
              </label>
              <div className="mt-2">
                <input
                  id="name"
                  name="name"
                  type="text"
                  autoComplete="given-name"
                  value={formData.name}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                />
                {errors.name && (
                  <div className="text-red-500 text-sm">{errors.name}</div>
                )}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Your email
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                />
                {errors.email && (
                  <div className="text-red-500 text-sm">{errors.email}</div>
                )}
              </div>
            </div>

            <div className="col-span-full">
              <label
                htmlFor="message"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Your message
              </label>
              <div className="mt-2">
                <textarea
                  id="message"
                  name="message"
                  rows={3}
                  value={formData.message}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-800 sm:text-sm sm:leading-6"
                />
                {errors.message && (
                  <div className="text-red-500 text-sm">{errors.message}</div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Terms and conditions
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            By submitting this form, you agree to the following terms and
            conditions:
          </p>

          <div className="space-y-10">
            <fieldset>
              <div className="mt-2 space-y-2">
                <div className="relative flex gap-x-3">
                  <div className="flex h-6 items-center">
                    <input
                      id="agreePrivacyPolicy"
                      name="agreePrivacyPolicy"
                      type="checkbox"
                      checked={formData.agreePrivacyPolicy}
                      onChange={handleChange}
                      className="h-4 w-4 rounded border-gray-300 text-teal-600 focus:ring-sky-800"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label
                      htmlFor="agreePrivacyPolicy"
                      className="font-medium text-gray-900"
                    >
                      Agree to Privacy Policy
                    </label>
                    {errors.agreePrivacyPolicy && (
                      <div className="text-red-500 text-sm">
                        {errors.agreePrivacyPolicy}
                      </div>
                    )}
                  </div>
                </div>

                <div className="relative flex gap-x-3">
                  <div className="flex h-6 items-center">
                    <input
                      id="agreeTerms"
                      name="agreeTerms"
                      type="checkbox"
                      checked={formData.agreeTerms}
                      onChange={handleChange}
                      className="h-4 w-4 rounded border-gray-300 text-teal-600 focus:ring-sky-600"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label
                      htmlFor="agreeTerms"
                      className="font-medium text-gray-900"
                    >
                      Agree to Terms and Conditions
                    </label>
                    {errors.agreeTerms && (
                      <div className="text-red-500 text-sm">
                        {errors.agreeTerms}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>

      <div className="max-w-5xl mt-6 mx-auto flex items-center justify-center gap-x-10">
        <button
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="rounded-md bg-sky-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
        >
          Send
        </button>
      </div>
      {message && (
        <div className="mt-4 text-sm text-gray-900 text-center">{message}</div>
      )}
    </form>
  );
}
