import CTA from "../../components/CTA";
import Clients from "../../components/Clients";

import WorkWithUs from "../../components/WorkWithUs";
import Highlights from "../../components/highlights";
import SeparatorRight from "../../components/SeparatorRight";
import TransferHero from "./TransferHero";
import TransferList from "./TransferList";
import Repatriation from "./Repatriation";
import Compliance from "../../components/Compliance";

export default function Transfer() {
  return (
    <div>
      <TransferHero />
      <SeparatorRight />
      <TransferList />
      <Repatriation />
      <Compliance />
      <Highlights />
      <CTA />
      <Clients />
      <WorkWithUs />
    </div>
  );
}
