import { PhoneIcon } from "@heroicons/react/20/solid";
import { ReactComponent as EmailIcon } from "../assets/mail-blue.svg";

export default function CTA() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-20 sm:px-6 sm:py-20 lg:px-8">
        <div className="relative isolate overflow-hidden bg-sky-900 px-6 py-10 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-10 lg:flex lg:gap-x-20 lg:px-18 lg:pt-0">
          <svg
            viewBox="0 0 1024 1024"
            aria-hidden="true"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
          >
            <circle
              r={512}
              cx={512}
              cy={512}
              fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
              fillOpacity="0.7"
            />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                <stop stopColor="#0f766e" />
                <stop offset={1} stopColor="#5eead4" />
              </radialGradient>
            </defs>
          </svg>
          <div className="mx-auto max-w-lg text-center lg:mx-0 lg:flex-auto lg:py-28 lg:text-left">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              We are just a call away.
              <br />
              Discover how we can assist you today.
            </h2>
            <h3 className="mt-6 text-lg leading-8 text-gray-300">
              Contact us to speak with a professional about how MES can support
              your event or to discuss your specific medical coverage needs.
            </h3>
          </div>
          <div className="relative mt-12 lg:mt-8 lg:w-1/2 content-center">
            <div className="mt-5 flex items-center justify-center gap-x-6 lg:justify-end">
              <a
                href="mailto:info@medicalemergencysolutions.co.uk"
                className="flex items-center rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-sky-900 shadow-sm hover:bg-teal-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600 "
              >
                <EmailIcon
                  aria-hidden="true"
                  className="h-6 w-6 text-sky-900 mr-2"
                />
                Email us
              </a>
              <a
                href="tel:+441582292826"
                className="flex items-center text-sm font-semibold text-white hover:text-teal-600"
              >
                <PhoneIcon aria-hidden="true" className="h-6 w-6  mr-2" />
                Call us
                <span aria-hidden="true" className="ml-1">
                  →
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
