import pattern from "../assets/pattern.svg";

export default function SeparatorLeft() {
  return (
    <div className="relative z-20 w-full flex justify-items-start">
      <img
        src={pattern}
        alt="Pattern"
        className="w-full -mt-16 min-h-40 lg:w-auto lg:-mt-28 lg:h-60  ml-0 mr-auto"
      />
    </div>
  );
}
