import CTA from "../../components/CTA";
import Clients from "../../components/Clients";
import SeparatorRight from "../../components/SeparatorRight";
import SeparatorLeft from "../../components/SeparatorLeft";
import WorkWithUs from "../../components/WorkWithUs";
import EventHero from "./EventHero";
import Staff from "../../components/Staff";

import EventStatement from "./EventStatement";
import EventTypes from "./EventTypes";
import Compliance from "../../components/Compliance";

export default function Events() {
  return (
    <div>
      <EventHero />
      <SeparatorRight />
      <EventStatement />
      <SeparatorLeft />
      <EventTypes />

      <CTA />
      <Compliance />
      <Staff />
      <Clients />
      <WorkWithUs />
    </div>
  );
}
