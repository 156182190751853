export default function UCCStats() {
  return (
    <div className="bg-white py-20 sm:py-30" id="UCC-section">
      <div className="mx-10 mb-20 flex justify-center">
        <h2 className="mt-2 text-3xl max-w-7xl tracking-tight text-gray-900 sm:text-4xl text-center ">
          A convenient, <strong className="text-sky-800">walk-in</strong>{" "}
          service for patients over 18, dealing with a range of{" "}
          <strong className="text-sky-800">non-emergency</strong> injuries and
          illnesses{" "}
          <strong className="text-sky-800">
            without the need for appointments or referrals.
          </strong>
        </h2>
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
          <div className="mx-auto flex max-w-xs flex-col gap-y-4">
            <dt className="text-base leading-7 text-gray-600">
              Open from 8am to 7pm, 365 days a year – including bank holidays.
            </dt>
            <dd className="order-first text-4xl font-semibold tracking-tight text-gray-900 md:text-4xl">
              Extended Hours
            </dd>
          </div>
          <div className="mx-auto flex max-w-xs flex-col gap-y-4">
            <dt className="text-base leading-7 text-gray-600">
              Patients receive full assessment and the necessary diagnostics,
              such as ultrasound, chest X-ray, or blood tests.
            </dt>
            <dd className="order-first text-4xl font-semibold tracking-tight text-gray-900 md:text-4xl">
              On-Site Services
            </dd>
          </div>
          <div className="mx-auto flex max-w-xs flex-col gap-y-4">
            <dt className="text-base leading-7 text-gray-600">
              If needed, patients can receive onward referrals to hospital
              specialists or private GPs.
            </dt>
            <dd className="order-first text-4xl font-semibold tracking-tight text-gray-900 md:text-4xl">
              Onward referrals
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
