import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { ReactComponent as LinkedinIcon } from "../../assets/linkedin.svg";

export default function Directors() {
  const people = [
    {
      name: "Dr. Sanooj Soni",
      role: "Co-Founder / Director",
      intro:
        "Consultant in Intensive Care Medicine and Anaesthesia in the NHS and private sector. Dr Soni is a founding director of MES and embodies the ideal fusion of medical competence and administrative prowess.",
      href: "https://www.linkedin.com/in/sanooj-soni-84624a83/?originalSubdomain=uk",

      photo: "/images/directors/soni.jpg",

      icon: LinkedinIcon,
    },
    {
      name: "Dr. Farhan Zaidi",
      role: "Co-Founder / Director",
      intro:
        "Emergency Medicine Consultant in the NHS and private sector. As a founding director of MES, Dr Zaidi has been instrumental in driving the company's success, securing multiple key contracts under his guidance.",

      href: "https://www.linkedin.com/in/farhan-zaidi/",

      photo: "/images/directors/zaidi.jpg",
      icon: LinkedinIcon,
    },
    {
      name: "Dr. Umeer Waheed",
      role: "Director",
      intro:
        "Consultant in Intensive Care Medicine and Anaesthesia in the NHS and private sector. As director of MES, Dr Waheed brings a unique blend of medical proficiency and strategic leadership to the forefront.",

      href: "http://www.criticalcareconsult.co.uk/",

      photo: "/images/directors/WAHEED.jpg",
      icon: GlobeAltIcon,
    },
    {
      name: "Dr. John Cousins",
      role: "Director",
      intro:
        "Consultant in Intensive Care Medicine and Anaesthesia in the NHS and private sector. Dr Cousins has significant international and UK clinical experience and is a transformative asset to MES's success.",

      href: "https://www.drjmc.com/",

      photo: "/images/directors/cousins.jpg",
      icon: GlobeAltIcon,
    },
  ];

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
        <div className="max-w-2xl">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Meet our leadership
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Dedicated to finding the best solutions, our vast experience in
            Acute and Critical Care and Emergency Medicine brings confidence and
            safety to our clients.
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Visit the director's personal website to learn more.
          </p>
        </div>
        <ul className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
          {people.map((person) => (
            <li key={person.name}>
              <div className="flex items-start gap-x-6">
                <img
                  alt={`${person.name} portrait`}
                  src={person.photo}
                  className="h-20 w-20 rounded-full"
                />
                <a href={person.href} target="_blank" rel="noopener noreferrer">
                  <div>
                    <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                      {person.name}
                    </h3>
                    <p className="text-sm font-semibold leading-6 text-sky-800">
                      {person.role}
                    </p>
                    <p className="text-sm  leading-6 text-grey-600">
                      {person.intro}
                    </p>
                    <person.icon
                      aria-hidden="true"
                      className="h-5 w-5 flex-none group-data-[open]:rotate-180 mt-1 text-sky-800"
                    />
                  </div>
                </a>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
